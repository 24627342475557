body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
}

.dropzone:hover,
.dropzone:active {
  background-color: #fcfcfc;
  border-color: #5f5fc4;
}


/* Remove these custom styles in case post module not using draftjs anymore */
/* Override default Draftjs ul style */

.DraftEditor-root .notranslate.public-DraftEditor-content div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 14px;
}

li.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR>div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 0;
}

li.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR>div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 0;
}

li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR>div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 0;
}

li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-reset.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR>div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 0;
}

li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR>div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 0;
}

li.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-reset.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR>div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 0;
}

li.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-reset.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR>div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 0;
}

.modal-post .DraftEditor-root .notranslate.public-DraftEditor-content blockquote div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr,
.modal-post .DraftEditor-root .notranslate.public-DraftEditor-content .public-DraftStyleDefault-ul div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr,
.DraftEditor-root .notranslate.public-DraftEditor-content>div>*:first-child .public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  margin-top: 0;
}

.modal-post ul .public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 20px;
}

ul.public-DraftStyleDefault-ul {
  padding-left: 0px !important;
}

li.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  flex: none;
  content: counter(ol0) ". ";
  counter-increment: ol0;
  padding-right: 0px;
  left: unset;
  position: unset;
  width: max-content;
}

ol>li.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  display: flex;
  margin-left: 0;
}

ol>li.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  display: flex;
  margin-left: 0px;
}

li.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  flex: none;
  margin-left: 2em;
  content: counter(ol0) "." counter(ol1) ". ";
  counter-increment: ol1;
  inline-size: max-content;
  left: unset;
  position: unset;
  width: max-content;
}

ol>li.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 0px;
}

li.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  flex: none;
  margin-left: 3em;
  content: counter(ol0) "." counter(ol1) "." counter(ol2) ". ";
  counter-increment: ol2;
  inline-size: max-content;
  left: unset;
  position: unset;
  width: max-content;
}

