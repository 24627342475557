
.contentItem {
    word-break: break-word;
    color: rgb(40 46 83 / 1);
}
  
.contentItem p {
    font-size: 0.9375rem;
    font-weight: 400;
}
    
.contentItem h1 {
    font-size: 1.75rem;
    line-height: 2.5rem;
    white-space: pre-wrap;
    font-weight: 500;
    margin-top: 14px;
}
    
.contentItem h2 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    white-space: pre-wrap;
    font-weight: 500;
    margin-top: 14px;
}

.contentItem h3 {
    font-size: 1.25rem;
    line-height: 1.875rem;
    white-space: pre-wrap;
    font-weight: 500;
    margin-top: 14px;
}

.contentItem h4 {
    font-size: 1.0625rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
    font-weight: 500;
    margin-top: 14px;
}

.contentItem blockquote {
    background: #F2F5F8;
    border-left: 6px solid #7335C0;
    margin-top: 14px;
    padding: 8px 12px;
    margin-left: 0;
    margin-right: 0;
}

.contentItem blockquote p {
    display: inline-block;
    margin: 8px 12px;
}

.contentItem ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 14px 0 0 -1px;
}

.contentItem ul ul {
    list-style-type: circle;
}

.contentItem ul ul ul {
    list-style-type: square;
}

.contentItem ul>li>ul {
    padding-left: 20px;
    margin: 0;
}

.contentItem ol {
    margin-top: 14px;
    counter-reset: section;
    list-style: none;
    list-style-type: none;
    padding-inline-start: 0;
}

.contentItem ol>li>ol {
    padding-left: 20px;
    margin: 0;
}

.contentItem ul>li>p {
    margin: 0;
}

.contentItem ol>li>div {
    display: inline;
}

.contentItem ol>li>p {
    display: inline;
}

.contentItem ol>li:before {
    counter-increment: section;
    content: counters(section, ".") ".";
    padding-right: 4px;
}

.contentItem div>p {
    margin-top: 14px;
}

.contentItem code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: var(--color-neutral-muted);
    border-radius: 6px;
}

.contentItem li>br {
    display: none;
}

/* Remove margin (if any) from paragraph node inside table cell */
.contentItem td[class*='slate-td'] div[class*='slate-p'] {
    margin: 0;
}
